<template>
  <form>
    <span class="arrow">⬇⬇</span>

    <h1>What shall we call you ? 👩‍🚀</h1>
    <p>
      First we need to connect this device to your ideas. This name is what
      allows you to create and edit your nodes and this name can be anything you
      like, this name is always anonymous. <strong>This is required.</strong>
    </p>
    <div class="breaker-one">
      <label for="name">Your Name: {{ nameFormatted }}</label>
      <input
        v-model.trim="name"
        type="text"
        id="name"
        ref="name"
        placeholder="type name here!"
        autocorrect="off"
        autocapitalize="none"
        required
      />
    </div>
    <h2>Next start or join a <span class="long">microcosm !</span> 🚀</h2>

    <p>
      Start your own microcosm to store your ideas and ask others to join you,
      either tell them the microcosm name or share the URL and add the ending;
      /microcosm/<strong>nameofyourmicrocosm</strong>
    </p>

    <div class="breaker-two">
      <label for="microcosm">Microcosm:</label>

      <input
        v-model.trim="microcosm"
        ref="microcosm"
        type="text"
        id="microcosm"
        placeholder="type microcosm name here!"
        autocorrect="off"
        autocapitalize="none"
      />
      <p class="validation">{{ error }}</p>
      <router-link to="/collect">
        <button type="button" @click="setName(name), setMicrocosm()">
          Start or Join
        </button>
      </router-link>
      <button class="configButton" type="button" @click="settings = !settings">
        Change CouchDB Configuration
      </button>
    </div>

    <template v-if="settings">
      <h2>Configure your own <span class="long">CouchDB !</span> 👩‍🔧</h2>

      <p>
        CouchDB is what allows you to sync nodes (your ideas) between your team.
        You need to have your own CouchDB set up. Otherwise leave this empty and
        you will continue to use the default cloud instance of CouchDB.
      </p>

      <div class="breaker-three">
        <label for="protocol"> Protocol:</label>
        <input
          v-model.trim="protocol"
          type="text"
          id="protocol"
          placeholder="type protocol here"
          autocorrect="off"
          autocapitalize="none"
        />

        <label for="protocol"> CouchDB username:</label>
        <input
          v-model.trim="username"
          type="text"
          id="username"
          placeholder="type CouchDB username here"
          autocorrect="off"
          autocapitalize="none"
        />

        <label for="protocol"> password:</label>
        <input
          v-model="password"
          type="password"
          id="password"
          placeholder="type password here"
          autocorrect="off"
          autocapitalize="none"
        />

        <label for="protocol"> URL:</label>
        <input
          v-model.trim="url"
          type="text"
          id="url"
          autocomplete="url"
          placeholder="type url here"
          autocorrect="off"
          autocapitalize="none"
        />

        <button
          type="button"
          @click="configureRemote(protocol, username, password, url)"
        >
          Save
        </button>
      </div>
    </template>
  </form>
</template>

<script>
export default {
  name: 'JoinMicrocosm',

  // directives: {
  //   focus: {
  //     mounted(el) {
  //       el.focus()
  //     },
  //   },
  // },

  data: function () {
    return {
      error: '',
      // what you are calling yourself / device
      name: '',
      nameSet: false,
      nameFormatted: '',
      // Next dataset related to microcosm
      microcosm: '',
      microcosmSet: false,
      microcosmFormatted: '',
      settings: false,
      protocol: '',
      username: '',
      password: '',
      url: '',
    }
  },

  methods: {
    setName(name) {
      // format name so no spaces or dashes PouchDB/ CouchDB doesn't like them
      var lowercasename = this.name.toLowerCase()
      var spacesremoved = lowercasename.split(' ').join('')
      this.nameFormatted = spacesremoved.split('-').join('')
      if (name == '') {
        this.error = 'Please set your name above first ☺️'
      } else {
        this.nameSet = true
      }
      localStorage.setItem('nogg_name', this.nameFormatted)
    },

    setMicrocosm() {
      if (this.nameSet == true) {
        this.$router.push({ path: '/collect' })
        // format microcosm so no spaces or dashes PouchDB/ CouchDB doesn't like them
        var lowercasemicrocosm = this.microcosm.toLowerCase()
        var spacesremoved = lowercasemicrocosm.split(' ').join('')
        this.microcosmFormatted = spacesremoved.split('-').join('')
        this.microcosmSet = true
        localStorage.setItem('nogg_microcosm', this.microcosmFormatted)
        // now we sent this same data set to the store
        var devicename = this.nameFormatted
        var microcosm = this.microcosmFormatted
        this.$store.dispatch('setMicrocosm', { devicename, microcosm })
        this.$store.dispatch('startDB')
      }
    },

    configureRemote(protocol, username, password, url) {
      // console.log(protocol, username, password, url)
      this.$store.dispatch('configureRemote', {
        protocol,
        username,
        password,
        url,
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin-top: -0.5em;
  font-size: 5.2em;
  line-height: 1.2em;
}

h2 {
  font-size: 5.2em;
  line-height: 1.2em;
  margin: -0.5em 0 0 0;
  padding: 0em;
  font-family: houschka-rounded, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: black;
}

.validation {
  font-weight: 700;
  font-style: bold;
  color: rgb(104, 39, 255);
}

.arrow {
  font-size: 6em;
  color: #1cc469;
}

.breaker-one {
  padding: 1em;
  margin-left: -1em;
  margin-right: -0.4em;
  margin-bottom: 2.5em;
  background-color: rgba(196, 47, 152, 0.4);
}

.breaker-two {
  padding: 1em;
  margin-left: -1em;
  margin-right: -0.4em;
  margin-bottom: 2.5em;
  background-color: rgba(37, 194, 194, 0.4);
}

.breaker-three {
  padding: 1em;
  margin-left: -1em;
  margin-right: -0.4em;
  margin-bottom: 1em;
  background-color: rgba(196, 47, 152, 0.4);
}

label,
button {
  display: block;
}

input {
  margin-top: 0.8em;
  border: none;
  padding: 1em;
  font-size: 1em;
}
button {
  display: block;
  cursor: pointer;
  background-color: #1cc469;
  border: none;
  margin-top: 1em;
  padding: 1em;
  font-size: 1em;
}
.configButton {
  background-color: rgba(196, 47, 152, 0.4);
}
</style>
