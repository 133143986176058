<template>
  <div>
    <h1>Messy mapping tool for multiplayer thinking</h1>
    <p>
      nodenogg.in is free/libre open source software, built in the open,
      inclusive by design, private by design, human led design, humane by
      design, delightful by design, intuitive and decentralised by design.
    </p>
    <p>
      nodenogg.in is a <em>messy</em> serendipitous
      <em>multiplayer</em> co-creation space to <em>play</em> and
      <em>push</em> ideas together. nodenogg.in is designed to support a new
      type of <em>co-creation</em>, a messy <em>spatial</em> canvas where
      <em>makers</em> can <em>tinker</em> with ideas and connect thoughts.
    </p>
    <p>
      nodenogg.in built from scratch with 💛 using open accessible web tech
      HTML, CSS, Javascript, SVG, Vue 3, PouchDB and CouchDB (and a few plugins
      such as markdown).
    </p>
    <p>
      Working Vue 2 alpha version can be played with at
      <a href="https://alpha.nodenogg.in">alpha.nodenogg.in</a>
    </p>
    <p>Made by Adam Procter</p>
    <p>
      nodenogg.in is made under a GNU AFFERO GENERAL PUBLIC
      <a
        href="https://gitlab.adamprocter.co.uk/nn/nodenoggin-vue3/-/blob/main/LICENSE"
        >LICENSE</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: 'TellmeMore',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 3em;
}

.info {
  font-style: italic;
}

em {
  font-style: normal;
  font-weight: 700;
  background-color: #1cc469;
  padding: 0 1em 0 1em;
}
</style>
