import PouchDB from 'pouchdb'
var pouchdb
var remote

export const state = {
  microcosmName: '',
  deviceName: '',
  remoteAddress: '',
  configRemote: [],
  pouchdb: {},
}

export const mutations = {
  SET_MICROCOSM(state, e) {
    state.deviceName = e.devicename
    state.microcosmName = e.microcosm

    pouchdb = new PouchDB(state.microcosmName)
    state.pouchdb = pouchdb

    if (state.configRemote && state.configRemote.length > 0) {
      remote =
        state.configRemote[0].protocol +
        '://' +
        state.configRemote[0].username +
        ':' +
        state.configRemote[0].password +
        '@' +
        state.configRemote[0].url +
        '/' +
        state.microcosmName +
        '/'
    } else {
      remote =
        process.env.VUE_APP_COUCH_HTTP +
        '://' +
        process.env.VUE_APP_COUCH_USER +
        ':' +
        process.env.VUE_APP_COUCH_PASS +
        process.env.VUE_APP_COUCH_URL +
        state.microcosmName +
        '/'
    }
    state.remoteAddress = remote
  },

  SET_REMOTE(state, e) {
    state.configRemote = [
      {
        protocol: e.protocol,
        username: e.username,
        password: e.password,
        url: e.url,
      },
    ]
  },
}

export const actions = {
  setMicrocosm: ({ commit }, e) => {
    commit('SET_MICROCOSM', e)
  },
  configureRemote: ({ commit }, e) => {
    commit('SET_REMOTE', e)
  },

  startDB: (vuexContext) => {
    // if (pouchdb == undefined) {
    //   var lastmicrocosm = localStorage.getItem('nogg_microcosm')
    //   pouchdb = new PouchDB(lastmicrocosm)
    // }
    pouchdb.replicate.from(remote).on('complete', function () {
      // GET_MYNODES from myNodes.js ACTION
      vuexContext.dispatch('getMynodes', null, { root: true })
      // vuexContext.dispatch('getPositions', null, { root: true })
      pouchdb
        .sync(state.remoteAddress, {
          live: true,
          since: 'now',
          retry: true,
        })
        .on('change', function () {
          vuexContext.dispatch('getOthernodes', null, { root: true })
          //  vuexContext.dispatch('getEmoji', null, { root: true })
          //  vuexContext.dispatch('getPositions', null, { root: true })
        })
        .on('paused', function () {})
        .on('active', function () {
          // vuexContext.dispatch('getNodes', null, { root: true })
        })
        .on('denied', function () {})
        .on('complete', function () {})
        .on('error', function (err) {
          console.log(err)
        })
    })
  },
}
export const getters = {}
