<template>
  <div id="nav">
    <router-link to="/">Start</router-link> |
    <router-link to="/credits">Credits</router-link> |
    <router-link to="/collect">Collect</router-link> |
    <router-link to="/allnodes">All Nodes</router-link> |
    <router-link to="/spatial">Spatial</router-link>
  </div>
  <router-view />
</template>

<style>
@import url('https://use.typekit.net/mwd7zcu.css');

#app {
  font-family: source-code-pro, monospace;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav a {
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #1cc469;
}

h1 {
  margin: 0.2em 0 0 0;
  padding: 0em;
  font-size: 6.2em;
  font-family: houschka-rounded, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: black;
}

h2 {
  margin: 0em 0em 1em 0.3em;
  font-size: 1.1em;
  font-family: houschka-rounded, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #707070;
}

.long {
  word-wrap: break-word;
  width: 305px;
  line-height: 1em;
}

.highlight {
  color: #1cc469;
}

@media only screen and (min-width: 630px) {
  h2 {
    margin-top: -1em;
  }
  .long {
    word-wrap: normal;
  }
}
</style>
