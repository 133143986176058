<template>
  <header class="header">
    <h1 class="long">nodenogg<span class="highlight">.in</span></h1>
    <h2>a messy mapping tool for multiplayer thinking</h2>
  </header>
  <nav class="nav">
    <a role="button" class="letsplay" href="#" @click="letsPlay"
      >let's play !</a
    >
    <a role="button" class="tellmemore" href="#" @click="tellMore"
      >tell me more</a
    >
  </nav>

  <main v-if="isStart" ref="start">
    <JoinMicrocosm />
  </main>

  <aside v-if="isStart == false" ref="moreinfo">
    <TellmeMore />
  </aside>
</template>

<script>
// @ is an alias to /src
import JoinMicrocosm from '@/components/JoinMicrocosm.vue'
import TellmeMore from '@/components/TellmeMore.vue'

export default {
  name: 'Start',
  components: {
    JoinMicrocosm,
    TellmeMore,
  },
  data() {
    return {
      isStart: null,
    }
  },

  methods: {
    letsPlay() {
      this.isStart = true
      setTimeout(() => {
        const el = this.$refs.start
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
    },
    tellMore() {
      this.isStart = false
      setTimeout(() => {
        const el = this.$refs.moreinfo
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
    },
  },
}
</script>

<style scoped>
aside {
  max-width: 800px;
}
a {
  display: block;
  font-size: 1em;
  color: black;
  text-decoration-line: none;
  margin: 1em 0.8em;
  padding: 1em 1.2em;
}

.tellmemore {
  background-color: rgba(196, 47, 152, 0.4);
}

.letsplay {
  background-color: rgba(37, 194, 194, 0.4);
}

@media only screen and (min-width: 330px) {
  a {
    display: inline-block;
  }
}
</style>
