var pouchdb
var deviceName

var docPositions

export const state = {
  myNodes: [],
  activeNode: {},
}

export const mutations = {
  ADD_NODE() {
    return pouchdb
      .get(deviceName)
      .then(function () {
        var uniqueid =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15)
        pouchdb.get(deviceName).then(function (doc) {
          doc.nodes.push({
            node_id: uniqueid,
            node_text: '',
            node_owner: deviceName,
            node_type: 'sheet',
            node_shape: 'square',
            node_deleted: false,
            node_readmode: false,
            node_color: '#9BC2D8',
          })

          return pouchdb
            .put({
              _id: deviceName,
              _rev: doc._rev,
              nodes: doc.nodes,
            })
            .then(function () {
              return pouchdb.get(deviceName).then(function (doc) {
                state.myNodes = doc.nodes
                var end = Object.keys(state.myNodes).length - 1
                const newNode = {
                  nodeid: state.myNodes[end].node_id,
                  nodetext: state.myNodes[end].node_text,
                }
                // OLD CODE:
                // this was to set quick focus on new nodes
                // i think... need to check old code
                state.activeNode = newNode
              })
            })
            .catch(function (err) {
              console.log(err)
            })
        })
        pouchdb.get(docPositions).then(function (doc) {
          doc.positions.push({
            node_id: uniqueid,
            node_x: 0,
            node_y: 0,
            node_width: 200,
            node_height: 370,
            node_zindex: 0,
            node_sort: 0,
            node_radius: 0,
            node_shape: 0,
          })
          return pouchdb
            .put({
              _id: docPositions,
              _rev: doc._rev,
              positions: doc.positions,
            })
            .catch(function (err) {
              console.log(err)
            })
        })
      })
      .catch(function (err) {
        // HITS a 404 on very first node being created
        if (err.status == 404) {
          var uniqueid =
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15)

          pouchdb
            .put({
              _id: deviceName,
              nodes: [
                {
                  node_id: uniqueid,
                  node_text: '',
                  node_owner: deviceName,
                  node_type: 'sheet',
                  node_shape: 'square',
                  node_deleted: false,
                  node_readmode: false,
                  node_color: '#9BC2D8',
                },
              ],
            })
            .then(function () {
              return pouchdb.get(deviceName).then(function (doc) {
                state.myNodes = doc.nodes
                var end = Object.keys(state.myNodes).length - 1
                const newNode = {
                  nodeid: state.myNodes[end].node_id,
                  nodetext: state.myNodes[end].node_text,
                }
                // OLD CODE:
                // this was to set quick focus on new nodes
                // i think... need to check old code
                state.activeNode = newNode
              })
            })
            .catch(function (err) {
              console.log(err)
            })
          pouchdb.get(docPositions).then(function (doc) {
            doc.positions.push({
              node_id: uniqueid,
              node_x: 0,
              node_y: 0,
              node_width: 200,
              node_height: 370,
              node_zindex: 0,
              node_sort: 0,
            })
            return pouchdb
              .put({
                _id: docPositions,
                _rev: doc._rev,
                positions: doc.positions,
              })
              .catch(function (err) {
                console.log(err)
              })
          })
        }
      })
  },

  GET_MY_NODES() {
    pouchdb
      .get(deviceName)
      .then(function (doc) {
        var i
        for (i = 0; i < Object.keys(doc.nodes).length; i++) {
          state.myNodes = doc.nodes
        }
      })
      .catch(function () {
        //    console.log(err)
      })
  },

  EDIT_NODE(state, e) {
    var i
    for (i = 0; i < Object.keys(state.myNodes).length; i++) {
      if (e.nodeid == state.myNodes[i].node_id) {
        state.myNodes[i].node_text = e.nodetext
      }
    }
    pouchdb
      .get(deviceName)
      .then(function (doc) {
        // put the store into pouchdb
        return pouchdb.bulkDocs([
          {
            _id: deviceName,
            _rev: doc._rev,
            nodes: state.myNodes,
          },
        ])
      })
      .then(function () {
        return pouchdb.get(deviceName).then(function (doc) {
          state.myNodes = doc.nodes
        })
      })
      .catch(function (err) {
        if (err.status == 404) {
          // pouchdb.put({  })
        }
      })
  },

  DISCARD_NODE(state, e) {
    var i
    for (i = 0; i < Object.keys(state.myNodes).length; i++) {
      if (e.e == state.myNodes[i].node_id) {
        state.myNodes[i].node_deleted = true
      }
    }
    pouchdb
      .get(deviceName)
      .then(function (doc) {
        return pouchdb.bulkDocs([
          {
            _id: deviceName,
            _rev: doc._rev,
            nodes: state.myNodes,
          },
        ])
      })
      .then(function () {
        return pouchdb.get(deviceName).then(function (doc) {
          state.myNodes = doc.nodes
        })
      })
      .catch(function (err) {
        if (err.status == 404) {
          // pouchdb.put({  })
        }
      })
  },

  RESTORE_NODE(state, e) {
    var i
    for (i = 0; i < Object.keys(state.myNodes).length; i++) {
      if (e.e == state.myNodes[i].node_id) {
        state.myNodes[i].node_deleted = false
      }
    }
    pouchdb
      .get(deviceName)
      .then(function (doc) {
        // put the store into pouchdb
        return pouchdb.bulkDocs([
          {
            _id: deviceName,
            _rev: doc._rev,
            nodes: state.myNodes,
          },
        ])
      })
      .then(function () {
        return pouchdb.get(deviceName).then(function (doc) {
          state.myNodes = doc.nodes
        })
      })
      .catch(function () {})
  },

  TOGGLE_MODE(state, e) {
    var i
    for (i = 0; i < Object.keys(state.myNodes).length; i++) {
      if (
        e.e == state.myNodes[i].node_id &&
        state.myNodes[i].node_readmode == false
      ) {
        state.myNodes[i].node_readmode = true
      } else if (
        e.e == state.myNodes[i].node_id &&
        state.myNodes[i].node_readmode == true
      ) {
        state.myNodes[i].node_readmode = false
      }
    }
    pouchdb
      .get(deviceName)
      .then(function (doc) {
        // put the store into pouchdb
        return pouchdb.bulkDocs([
          {
            _id: deviceName,
            _rev: doc._rev,
            nodes: state.myNodes,
          },
        ])
      })
      .then(function () {
        return pouchdb.get(deviceName).then(function (doc) {
          state.myNodes = doc.nodes
        })
      })
      .catch(function () {})
  },

  COLOR_NODE(state, e) {
    var i
    for (i = 0; i < Object.keys(state.myNodes).length; i++) {
      if (e.nodeid == state.myNodes[i].node_id) {
        state.myNodes[i].node_color = e.color
      }
    }
    pouchdb
      .get(deviceName)
      .then(function (doc) {
        return pouchdb.bulkDocs([
          {
            _id: deviceName,
            _rev: doc._rev,
            nodes: state.myNodes,
          },
        ])
      })
      .then(function () {
        return pouchdb.get(deviceName).then(function (doc) {
          state.myNodes = doc.nodes
        })
      })
      .catch(function () {})
  },

  SET_SHAPE(state, e) {
    var i
    for (i = 0; i < Object.keys(state.myNodes).length; i++) {
      if (e.nodeid == state.myNodes[i].node_id) {
        state.myNodes[i].node_shape = e.shape
      }
    }
    pouchdb
      .get(deviceName)
      .then(function (doc) {
        return pouchdb.bulkDocs([
          {
            _id: deviceName,
            _rev: doc._rev,
            nodes: state.myNodes,
          },
        ])
      })
      .then(function () {
        return pouchdb.get(deviceName).then(function (doc) {
          state.myNodes = doc.nodes
        })
      })
      .catch(function (err) {
        if (err.status == 404) {
          // pouchdb.put({  })
        }
      })
  },
}

export const actions = {
  addNode: ({ commit }, e) => {
    commit('ADD_NODE', e)
  },

  getMynodes: ({ commit }) => {
    commit('GET_MY_NODES')
  },

  editNode: ({ commit }, { nodeid, nodetext }) => {
    commit('EDIT_NODE', { nodeid, nodetext })
  },

  discardNode: ({ commit }, e) => {
    commit('DISCARD_NODE', e)
  },

  restoreNode: ({ commit }, e) => {
    commit('RESTORE_NODE', e)
  },

  toggleMode: ({ commit }, e) => {
    commit('TOGGLE_MODE', e)
  },

  colorNode: ({ commit }, { nodeid, color }) => {
    commit('COLOR_NODE', { nodeid, color })
  },

  setShape: ({ commit }, { nodeid, shape }) => {
    commit('SET_SHAPE', { nodeid, shape })
  },

  getMicrocosm(vuexContext) {
    deviceName = vuexContext.rootState.setup.deviceName
    pouchdb = vuexContext.rootState.setup.pouchdb
    docPositions = vuexContext.rootState.allPositions.docPositions
  },
}

export const getters = {}
