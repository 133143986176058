var pouchdb
var deviceName
var docEmoji
var docPositions

export const state = {
  allNodes: [],
  otherNodes: [],
}

export const mutations = {
  GET_ALL_NODES(state) {
    pouchdb
      .allDocs({
        include_docs: true,
        attachments: true,
      })
      .then(function (doc) {
        state.allNodes = doc.rows
      })
      .catch(function (err) {
        console.log(err)
      })
  },

  SET_OTHER_NODES(state) {
    state.otherNodes = []
    var i
    var j
    // console.log(docEmoji)
    // console.log(docPositions)
    for (i = 0; i < Object.keys(state.allNodes).length; i++) {
      if (
        state.allNodes[i].id != deviceName &&
        state.allNodes[i].id != docEmoji &&
        state.allNodes[i].id != docPositions
      ) {
        for (j = 0; j < Object.keys(state.allNodes[i].doc.nodes).length; j++) {
          const newNode = {
            node_id: state.allNodes[i].doc.nodes[j].node_id,
            node_text: state.allNodes[i].doc.nodes[j].node_text,
            node_deleted: state.allNodes[i].doc.nodes[j].node_deleted,
            node_color: state.allNodes[i].doc.nodes[j].node_color,
          }
          state.otherNodes.push(newNode)
        }
      }
    }
  },
}

export const actions = {
  getOthernodes: ({ commit }) => {
    commit('GET_ALL_NODES')
    commit('SET_OTHER_NODES')
  },

  setOthernodes: ({ commit }) => {
    commit('SET_OTHER_NODES')
  },

  getMicrocosm(vuexContext) {
    deviceName = vuexContext.rootState.setup.deviceName
    pouchdb = vuexContext.rootState.setup.pouchdb
    docEmoji = vuexContext.rootState.allEmoji.docEmoji
    docPositions = vuexContext.rootState.allPositions.docPositions
  },
}

export const getters = {}
