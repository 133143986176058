import { createStore } from 'vuex'

import * as setup from '@/store/modules/setup.js'
import * as myNodes from '@/store/modules/myNodes.js'
import * as otherNodes from '@/store/modules/otherNodes.js'
import * as allEmoji from '@/store/modules/allEmoji.js'
import * as allPositions from '@/store/modules/allPositions.js'

export const store = createStore({
  modules: {
    setup,
    myNodes,
    otherNodes,
    allEmoji,
    allPositions,
  },
})
export default store
