var pouchdb

export const state = {
  docEmoji: 'emojis',
  allEmoji: [],
}

export const mutations = {
  GET_EMOJI(state) {
    pouchdb
      .get(state.docEmoji)
      .then(function (doc) {
        state.allEmoji = doc.emojis
      })
      .catch(function (err) {
        if (err.status == 404) {
          return pouchdb.put({
            _id: state.docEmoji,
            emojis: [],
          })
        }
      })
  },
  ADD_EMOJI(state, e) {
    var uniqueid =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    pouchdb.get(state.docEmoji).then(function (doc) {
      doc.emojis.push({
        emoji_id: uniqueid,
        node_id: e.nodeid,
        emoji_unicode: e.unicode,
        emoji_annotation: e.annotation,
        emoji_skintone: e.skinTone,
        emoji_emoticon: e.emoticon,
      })
      return pouchdb
        .put({
          _id: state.docEmoji,
          _rev: doc._rev,
          emojis: doc.emojis,
        })
        .catch(function () {})
    })
  },
}

export const actions = {
  getEmoji: ({ commit }) => {
    commit('GET_EMOJI')
  },

  addEmoji: (
    { commit },
    { nodeid, unicode, annotation, skinTone, emoticon }
  ) => {
    commit('ADD_EMOJI', {
      nodeid,
      unicode,
      annotation,
      skinTone,
      emoticon,
    })
  },

  getMicrocosm(vuexContext) {
    pouchdb = vuexContext.rootState.setup.pouchdb
  },
}

export const getters = {}
