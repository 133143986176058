var pouchdb

export const state = {
  docPositions: 'positions',
  allPositions: [],
}

export const mutations = {
  GET_POSITIONS(state) {
    pouchdb
      .get(state.docPositions)
      .then(function (doc) {
        state.allPositions = doc.positions
      })
      .catch(function (err) {
        console.log(err)
        if (err.status == 404) {
          return pouchdb.put({
            _id: state.docPositions,
            positions: [],
          })
        }
      })
  },

  UPDATE_POSTION(state, e) {
    var i
    for (i = 0; i < Object.keys(state.allPositions).length; i++) {
      if (e.nodeid == state.allPositions[i].node_id) {
        state.allPositions[i].node_x = e.x
        state.allPositions[i].node_y = e.y
        state.allPositions[i].node_width = e.width
        state.allPositions[i].node_height = e.height
        state.allPositions[i].node_zindex = e.zindex
      }
    }

    pouchdb
      .get(state.docPositions)
      .then(function (doc) {
        return pouchdb.bulkDocs([
          {
            _id: state.docPositions,
            _rev: doc._rev,
            positions: state.allPositions,
          },
        ])
      })
      .then(function () {
        return pouchdb.get(state.global_pos_name).then(function (doc) {
          state.allPositions = doc.positions
        })
      })
      .catch(function (err) {
        if (err.status == 404) {
          // pouchdb.put({  })
        }
      })
  },
}

export const actions = {
  getPositions: ({ commit }) => {
    commit('GET_POSITIONS')
  },
  // movePos: ({ commit }, nodeid, xpos, ypos, width, height, zindex) => {
  //   commit('MOVE_POS', nodeid, xpos, ypos, width, height, zindex)
  // },
  getMicrocosm(vuexContext) {
    pouchdb = vuexContext.rootState.setup.pouchdb
  },
}

export const getters = {}
