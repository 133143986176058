import { createRouter, createWebHistory } from 'vue-router'
import Start from '../views/Start.vue'

function guardMyroute(to, from, next) {
  var hasMicrocosm = false
  if (localStorage.getItem('nogg_microcosm')) hasMicrocosm = true
  else hasMicrocosm = false
  if (hasMicrocosm) {
    next() // allow to enter route
  } else {
    next('/') // go to '/'
  }
}

const routes = [
  {
    path: '/',
    name: 'Start',
    component: Start,
  },
  {
    path: '/credits',
    name: 'Credits',
    // route level code-splitting
    // this generates a separate chunk (credits.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "credits" */ '../views/Credits.vue'),
  },
  {
    path: '/collect',
    name: 'Collect',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (credits.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "collect" */ '../views/Collect.vue'),
  },

  {
    path: '/allnodes',
    name: 'All Nodes',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (credits.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "cards" */ '../views/Nodes.vue'),
  },
  {
    path: '/spatial',
    name: 'Spatial',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (credits.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "cards" */ '../views/Spatial.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
